import {IAction} from 'redux/interface';
import {
  IArticle,
  IArticleCard,
  IFilter,
  ILibraryFiltersResponse,
  ILibraryResponse,
  IShareTemplate
} from './interfaces';
import {InferActionsTypes} from 'redux/helpers';

export const CLOSE_ARTICLE = 'CLOSE_ARTICLE';
export const closeArticle = (): IAction<null> => ({
  type: CLOSE_ARTICLE,
  payload: null
});

export const CONTENT_LOADED = 'CONTENT_LOADED';
export const contentLoadedAction = (content: ILibraryResponse): IAction<ILibraryResponse> => ({
  type: CONTENT_LOADED,
  payload: content
});

export const FILTER_LOADED = 'FILTER_LOADED';
export const libFiltersLoadedAction = (content: ILibraryFiltersResponse): IAction<ILibraryFiltersResponse> => ({
  type: FILTER_LOADED,
  payload: content
});

export const CLEAR_SELECTED_FILTERS = 'CLEAR_FILTERS';
export const libClearSelectedFiltersAction = (): IAction<null> => ({
  type: CLEAR_SELECTED_FILTERS,
  payload: null
});

export const ARTICLE_LOADED = 'ARTICLE_LOADED';
export const articleLoadedAction = (article: IArticle): IAction<IArticle> => ({
  type: ARTICLE_LOADED,
  payload: article
});

export const CHECK_FAVORITE = 'CHECK_FAVORITE';
export const checkFavoriteAction = (cardId: number): IAction<number> => ({
  type: CHECK_FAVORITE,
  payload: cardId
});

export const UNCHECK_FAVORITE = 'UNCHECK_FAVORITE';
export const uncheckFavoriteAction = (cardId: number): IAction<number> => ({
  type: UNCHECK_FAVORITE,
  payload: cardId
});

export const CHECK_MATERIAL = 'CHECK_MATERIAL';
export const checkMaterialAction = (contentId: number): IAction<number> => ({
  type: CHECK_MATERIAL,
  payload: contentId
});

export const UNCHECK_MATERIAL = 'UNCHECK_MATERIAL';
export const uncheckMaterialAction = (contentIds: number[]): IAction<number[]> => ({
  type: UNCHECK_MATERIAL,
  payload: contentIds
});

export const BACKPACK_CONTENT_LOADED = 'BACKPACK_CONTENT_LOADED';
export const backpackLoadedAction = (backpack: IArticleCard[]): IAction<IArticleCard[]> => ({
  type: BACKPACK_CONTENT_LOADED,
  payload: backpack
});

export const MATERIALS_CONTENT_LOADED = 'MATERIALS_CONTENT_LOADED';
export const materialsLoadedAction = (material: IArticleCard[]): IAction<IArticleCard[]> => ({
  type: MATERIALS_CONTENT_LOADED,
  payload: material
});

export const SET_SELECTED_FILTERS = 'SET_SELECTED_FILTERS';
export const setSelectedFilters = (filters: IFilter[]): IAction<IFilter[]> => ({
  type: SET_SELECTED_FILTERS,
  payload: filters
});

export const SET_SHARE_TEMPLATES = 'SET_SHARE_TEMPLATES';
export const setShareTemplates = (templates: IShareTemplate[]): IAction<IShareTemplate[]> => ({
  type: SET_SHARE_TEMPLATES,
  payload: templates
});
export const SET_SEND_MATERIALS_STATUS = 'SET_SEND_MATERIALS_STATUS';
export const setSendMaterialsStatus = (status: boolean): IAction<boolean> => ({
  type: SET_SEND_MATERIALS_STATUS,
  payload: status
});
const contentActions = {
  closeArticle,
  contentLoadedAction,
  libFiltersLoadedAction,
  articleLoadedAction,
  checkFavoriteAction,
  uncheckFavoriteAction,
  checkMaterialAction,
  uncheckMaterialAction,
  backpackLoadedAction,
  materialsLoadedAction,
  setSelectedFilters,
  setShareTemplates,
  setSendMaterialsStatus
};

export type ContentActionsType = InferActionsTypes<typeof contentActions>
