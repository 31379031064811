import axios, {AxiosResponse} from 'axios';
import {
  IArticle,
  IArticleCard,
  IFilter,
  ILibraryFiltersResponse,
  ILibraryResponse,
  ISendMaterials,
  IShareTemplate
} from '../content/interfaces';

export const filterContentApi = (filters: {
  filter: {
    type: 'SIMPLE' | 'EXTENDED',
    filters: IFilter[]
  },
  lang: string,
  offset: number,
  limit: number
}): Promise<AxiosResponse<ILibraryResponse>> => {
  return axios.post(
    `${process.env.REACT_APP_API_URL}/content/filter`,
    filters
  );
};

export const getLibFiltersApi = (filterType: 'SIMPLE' | 'EXTENDED', language: string): Promise<AxiosResponse<ILibraryFiltersResponse>> => {
  return axios.get(`${process.env.REACT_APP_API_URL}/content/filter/${filterType}`, {
    params: {
      language
    }
  });
};

export const getArticleApi = (id: string, language: string): Promise<AxiosResponse<IArticle>> => {
  return axios.get(`${process.env.REACT_APP_API_URL}/content/${id}`, {
    params: {
      language
    }
  });
};

export const getPublicArticleApi = (id: string, language: string): Promise<AxiosResponse<IArticle>> => {
  return axios.get(`${process.env.REACT_APP_API_URL}/content/open/${id}`, {
    params: {
      language
    }
  });
};

export const getMaterialsApi = (locale: string): Promise<AxiosResponse<IArticleCard[]>> => {
  return axios.get(
    `${process.env.REACT_APP_API_URL}/materials/list`,
    {
      params: {
        language: locale
      }
    }
  );
};

export const getFavoritesApi = (locale: string): Promise<AxiosResponse<IArticleCard[]>> => {
  return axios.get(
    `${process.env.REACT_APP_API_URL}/content/favorite/list`,
    {
      params: {
        language: locale
      }
    }
  );
};

export const addFavoriteApi = (cardId: number): Promise<AxiosResponse<{ value: string }>> => {
  return axios.post(
    `${process.env.REACT_APP_API_URL}/content/favorite/add`,
    cardId.toString()
  );
};

export const removeFavoriteApi = (cardId: number): Promise<AxiosResponse<{ value: string }>> => {
  return axios.post(
    `${process.env.REACT_APP_API_URL}/content/favorite/remove`,
    cardId.toString()
  );
};

export const addMaterialApi = (data: {
  contentId: number,
  language: string,
  comments: string
}): Promise<AxiosResponse<{ value: string }>> => {
  return axios.post(
    `${process.env.REACT_APP_API_URL}/materials/add`,
    data
  );
};

export const removeMaterialApi = (contentIds: number[]): Promise<AxiosResponse<{ value: string }>> => {
  return axios.post(
    `${process.env.REACT_APP_API_URL}/materials/remove`,
    contentIds
  );
};

export const getTemplatesApi = (locale: string): Promise<AxiosResponse<IShareTemplate[]>> => {
  return axios.get(
    `${process.env.REACT_APP_API_URL}/templates/list`,
    {
      params: {
        language: locale
      }
    }
  );
};

export const shareMaterialsApi = (data: {
  materials: ISendMaterials,
  contentLang: string
}): Promise<AxiosResponse<{ value: string }>> => {
  return axios.post(
    `${process.env.REACT_APP_API_URL}/materials/share`,
    {
      ...data.materials,
      contentLang: data.contentLang
    }
  );
};

export const saveJournalApi = (journal: IArticle): Promise<AxiosResponse<{ value: string }>> => {
  return axios.post(
    `${process.env.REACT_APP_API_URL}/content/journal/save`,
    {
      content: journal
    }
  );
};
